<script>
    import { pc28ColorRule, pk10ColorRule, currentColorRule } from '@/CONF.js'
    import { getAnimals } from "@/api/touzhu.js";
    import { lottery_record } from '@/api/beijing28';
    export default {
        components:{
            Header: () => import('components/base/Header'),
        },
        props:[
            'currentType',
        ],
        data() {
            return {
                currentPage:1,
                pc28ColorRule,
                pk10ColorRule,
                currentColorRule,
                drawingHistory:[],
                drawingHistoryRule:['num_one','num_two','num_three','num_four','num_five','num_six','num_seven','num_eight','num_nine','num_ten','sum','num_tema'],
                fetchEnd:false,

                header:{
                    mainTitle:getWord('trend3')
                },

                navitype:null,
                name:null,
                type:null,
                drawingHistoryFormat:null,
            };
        },
        inject: window._TEMPLATE!=='template-3'?[
            
            'checkDxds'
        ]:['checkDxds'],
        methods: {
            fetchData(currentPage=this.currentPage){
                var that=this;
                var lastMonth=parseInt((new Date().getTime()-3600*24*30*1000)/1000);
                var yesterday=parseInt((new Date().getTime()-3600*24*1000)/1000);

                var start=null;
                if (_TEMPLATE==='template-1'||_TEMPLATE==='template-2') {
                    start=localStorage.getItem('game') && (JSON.parse( localStorage.getItem('game') ).type==='lhc'||JSON.parse( localStorage.getItem('game') ).type==='lhcaomen'||JSON.parse( localStorage.getItem('game') ).type==='fc3d')
                        ?lastMonth
                        :yesterday;
                }else if (this.$route.query.type) {
                    start=this.$route.query.type && (this.$route.query.type==='lhc'||this.$route.query.type==='fc3d')
                        ?lastMonth
                        :yesterday;
                }else {
                    start=localStorage.getItem('game') && (JSON.parse( localStorage.getItem('game') ).type==='lhc'||JSON.parse( localStorage.getItem('game') ).type==='fc3d'||JSON.parse( localStorage.getItem('game') ).type==='wanneng'||JSON.parse( localStorage.getItem('game') ).type==='damacai'||JSON.parse( localStorage.getItem('game') ).type==='duoduo'||JSON.parse( localStorage.getItem('game') ).type==='yuedawan'||JSON.parse( localStorage.getItem('game') ).type==='shaba88'||JSON.parse( localStorage.getItem('game') ).type==='saimahui'||JSON.parse( localStorage.getItem('game') ).type==='xinjapowz')
                        ?lastMonth
                        :yesterday;
                }

                setTimeout(function(){
                    lottery_record(
                        that.type?that.type:JSON.parse(localStorage.getItem('game')).type,
                        currentPage,
                        20,
                        start,
                        parseInt((new Date().getTime())/1000)
                    ).then(result => {
                        var data=result.data.result.list;
                        data.forEach(item=>{
                            that.drawingHistory.push(item);
                        })
                        if (that.currentPage===result.data.result.totalPage)
                            that.fetchEnd=true;
                        else {
                            that.currentPage++;
                        }

                        that._drawingHistoryFormat()
                    })
                },0)
            },
            getDate(data){
                var _date=new Date(data*1000)
                var year=_date.getFullYear();
                var month=_date.getMonth()+1
                var date=_date.getDate()
                var hours=_date.getHours()
                var minutes=_date.getMinutes()
                var seconds=_date.getSeconds()
                function complement(item){
                    return item>9?item:'0'+item
                }
                return year+' '+complement(month)+'/'+complement(date)+' '+complement(hours)+':'+complement(minutes)+':'+complement(seconds)
            },
            gameType(){
                if (this.type) {
                    return this.type
                }else {
                    return localStorage.getItem('game') && JSON.parse( localStorage.getItem('game') ).type
                }
            },
            //前往投注路由
            right_click() {
                if(this.type.indexOf('lhc')===0 || this.type==='hljssc' || this.type==='tjssc' || this.type==='sscone' || this.type==='sscthree' || this.type==='sscfive' || this.type==='scone' || this.type==='scthree' || this.type==='scfive' || this.type==='pc28one' || this.type==='tencent' || this.type.indexOf('fastthree')>-1 || this.type.indexOf('fiveselect')>-1 || this.type==='fc3d' || this.type==='gxklsf' || this.type==='gdklten' || this.type==='jndxy' || this.type==='azxy5' || this.type==='azxy10'){
                    this.$router.push({path: "/t?type=" + this.type + "&name=" + this.name + "&room=0"});
                }else{
                    this.$router.push({path: "/beijing28/room_list?type=" + this.type + "&name=" + this.name});
                }
            },
            getZodiac(){
                return new Promise(resolve=>{
                    getAnimals().then(result =>{
                        if(result.data.code === 'SUCCESS'){
                            resolve(result.data.result);
                        }
                    })
                })
            },
            async _drawingHistoryFormat(){
                var that=this;
                var drawingHistoryEntries=[];
                var drawingHistoryValues=[];

                var zodiac=await this.getZodiac();

                function bindZodiac(){
                    var zodiacRule=zodiac;

                    Object.keys(zodiacRule).forEach(item=>{
                        var _zodiac = item.zodiac;
                        zodiacRule[item].numbers.forEach(_item=>{                                
                            drawingHistoryValues.filter(data=>{
                                return parseInt(data.value) === parseInt(_item)
                            }).forEach(__item=>{
                                __item.zodiac = zodiacRule[item].name;
                            })                                
                        })
                    })
                }

                function format(){

                    that.drawingHistory.forEach(item=>{
                        drawingHistoryValues=[];
                        that.drawingHistoryRule.forEach(rule=>{
                            
                            if (item[rule]!==undefined) {
                                drawingHistoryValues.push({
                                    value:item[rule],
                                    color:null
                                })
                            }
                        })

                        if (drawingHistoryValues && drawingHistoryValues.length!==0) {
                            that.currentColorRule().forEach(item => {
                                var _colorName = item.name;
                                var _color = item.color;
                                var _bgColor = item.bgColor?item.bgColor:null
                                item.related.forEach(item => {
                                    drawingHistoryValues.filter(data => {
                                        return parseInt(data.value) === parseInt(item)
                                    }).forEach(item=>{
                                        item.name = _colorName
                                        item.color = _color
                                        item.bgColor = _bgColor
                                    })
                                })
                            })

                            if(_TEMPLATE==='template-3'&&(that.$route.query.type&&that.$route.query.type.indexOf('lhc')===0||decodeURIComponent(window.location.search).indexOf('type=lhc')>-1)) {
                                bindZodiac()
                            }
                        }

                        drawingHistoryEntries.push(drawingHistoryValues)
                    })
                }

                format();                

                this.drawingHistoryFormat = drawingHistoryEntries
            }
        },
        directives: {
            'infinite-scroll': app.Mint.InfiniteScroll,
        },
        mounted(){
            if (this.$refs.drawingHistory) {
                this.CustomScroll(this.$refs.drawingHistory.id);
            }

            this.type = this.$route.query.type;
            this.name = this.$route.query.name;
            this.navitype = this.$route.query.navitype;

            this.fetchData();
        }
    }
</script>
<template>
    <div id="drawing-history" ref="drawingHistory" v-infinite-scroll="fetchData" infinite-scroll-distance="10" infinite-scroll-disabled="fetchEnd" :class="_TEMPLATE" v-if="drawingHistoryFormat">
        <!-- <Header v-if="currentType!=='no-header'"
            :mainTitle="header.mainTitle" 
            :backButton=false
        /> -->
        <!-- <template v-if="_TEMPLATE==='template-1'">
            <div v-for="(item,index) in drawingHistory">
                <h5>{{ getWord('number') }}<b>{{item.issue}}</b>{{ getWord('period') }} {{getDate(item.real_opened_at)}}</h5>
                <ul :dxds="checkDxds(item.sum)" :class="{ pc28:drawingHistoryFormat[0].length===4,dtclassic:drawingHistoryFormat[0].length===10 }">
                    <li v-for="item in drawingHistoryFormat[index]" :key="item.issue" :style="{ '--data-color':item.color,'--data-bg-color':item.bgColor }">{{item.value}}</li>
                </ul>
            </div>
        </template>  -->
        <!-- <template v-if="_TEMPLATE==='template-2'">
            <div v-for="(item,index) in drawingHistory">
                <h5>{{ getWord('number') }}<b>{{item.issue}}</b>{{ getWord('period') }}</h5>
                <ul :dxds="checkDxds(item.sum)" :class="{ pc28:drawingHistoryFormat[0].length===4,dtclassic:drawingHistoryFormat[0].length===10 }">
                    <li v-for="(item,_index) in drawingHistoryFormat[index]" :key="item.issue" :style="{ '--data-color':_index===3&&item.color,'--data-bg-color':_index===3&&item.bgColor }">{{item.value}}</li>                    
                </ul>
                <i>
                    <span v-for="_item in checkDxds(item.sum).split('')"
                        :class="{
                            red:(_item===getWord('da')||_item===getWord('dan')),
                            blue:(_item===getWord('small')||_item===getWord('shuang'))
                        }">
                            {{_item}}
                    </span>
                </i>
            </div>
        </template> -->
        <template>
            <a class="go-room" v-if="_TEMPLATE==='template-3'&&navitype == 'true'" @click="right_click()">{{ getWord('betting3') }}</a>
            <div class="item" v-for="(item,index) in drawingHistory">
                <h5 v-if="gameType().indexOf('wanneng')===-1&&gameType().indexOf('damacai')===-1&&gameType().indexOf('duoduo')===-1&&gameType().indexOf('yuedawan')===-1&&gameType().indexOf('shaba88')===-1&&gameType().indexOf('saimahui')===-1&&gameType().indexOf('xinjapowz')===-1">
                    <i>{{ getWord('number') }}<b>{{item.issue}}</b>{{ getWord('period') }}</i>
                    <span>{{ new Date(item.real_opened_at*1000).toISOString().split('T')[0] + '&nbsp;&nbsp;' + new Date(item.real_opened_at*1000).toTimeString().split(' ')[0] }}</span>
                </h5>
                <ul 
                    :dxds="checkDxds(item.sum)" 
                    :class="{ 
                        pc28:gameType().indexOf('28')>-1,
                        pk10:gameType().indexOf('sc')===0||gameType()===('xyft')||gameType()===('azxy10'),
                        ssc:gameType().indexOf('ssc')>-1,
                        klsf:gameType().indexOf('klsf')>-1||gameType().indexOf('gdklten')>-1,
                        ffc:gameType().indexOf('tencent')>-1,
                        lhc:gameType().indexOf('lhc')===0,
                        fc3d:gameType().indexOf('fc3d')>-1,
                        kuai3:gameType().indexOf('fastthree')>-1,
                        fiveselect:gameType().indexOf('fiveselect')>-1,
                        xync:gameType().indexOf('cqklten')>-1, 
                    }"
                >
                    <li v-if="gameType().indexOf('28')>-1" v-for="(item,_index) in drawingHistoryFormat[index]" :key="item.issue" :style="{ '--data-color':_index===3&&(Number(item.value)===13||Number(item.value)===14)?'#333333':item.color,'--data-bg-color':item.bgColor }">
                        {{item.value}}
                    </li>
                    <li v-if="gameType().indexOf('lhc')===-1&&gameType().indexOf('28')===-1&&gameType().indexOf('wanneng')===-1&&gameType().indexOf('damacai')===-1&&gameType().indexOf('duoduo')===-1&&gameType().indexOf('yuedawan')===-1&&gameType().indexOf('shaba88')===-1&&gameType().indexOf('saimahui')===-1&&gameType().indexOf('xinjapowz')===-1" v-for="(item,_index) in drawingHistoryFormat[index]" :key="item.issue" :style="{ '--data-color':item.color,'--data-bg-color':item.bgColor }">
                        <template v-if="gameType().indexOf('fastthree')>-1">
                            <span class="dice" :class="'dice-'+item.value"></span>
                        </template>
                        <template v-else-if="gameType().indexOf('klsf')>-1||gameType().indexOf('gdklten')>-1">
                            {{Number(item.value)<10?('0'+Number(item.value)):item.value}}
                        </template>
                        <template v-else>
                            {{item.value}}
                        </template>
                    </li>
                    <li v-if="gameType().indexOf('lhc')===0" :style="{ '--data-color':item.color,'--data-bg-color':item.bgColor }" v-for="(item,_index) in drawingHistoryFormat[index]" :class="item.name" :zodiac="item.zodiac||'?'">
                        {{item.value}}
                        <span>{{item.zodiac}}</span>
                    </li>

                    <li class="wanneng" v-if="gameType().indexOf('wanneng')===0||gameType()==='damacai'||gameType()==='duoduo'||gameType()==='yuedawan'||gameType()==='shaba88'||gameType()==='saimahui'||gameType()==='xinjapowz'">
                        <h4>{{ getWord('number') }} {{ item.issue }} {{ getWord('period') }} {{ new Date(item.closed_at*1000).toLocaleDateString() }}</h4>
                        <div class="level-1">
                            {{ getWord('shoujiang') }} {{ JSON.parse(item.open_number).one }}
                        </div>
                        <div class="level-2">
                            <span>{{ getWord('erjiang') }} {{ JSON.parse(item.open_number).two }}</span>
                            <span>{{ getWord('sanjiang') }} {{ JSON.parse(item.open_number).three }}</span>
                        </div>
                        <div class="level-3">
                            <span>{{ getWord('tebiejiang') }}</span>
                            <span>{{ getWord('anweijiang') }}</span>
                        </div>
                        <div class="level-4">
                            <span>
                                <b v-for="_item in JSON.parse(item.open_number).four">{{ _item }}</b>
                            </span>
                            <span>
                                <b v-for="_item in JSON.parse(item.open_number).five">{{ _item }}</b>
                            </span>
                        </div>
                    </li>
                </ul>
                <i v-if="gameType().indexOf('28')>-1">
                    <span v-for="_item in checkDxds(item.sum).slice(1,3).split('')" 
                        :class="{
                            red:(_item===getWord('da')||_item===getWord('dan')),
                            blue:(_item===getWord('small')||_item===getWord('shuang'))
                        }">
                            {{_item}}
                    </span>
                </i>
            </div>
        </template>
    </div>
</template>
<style lang="scss" scoped>
@import "@@/assets/style/_variables";
#drawing-history {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    &.template-3 {

        .go-room {
            font-size: .36rem;
            height: .9rem;
            line-height: .9rem;
            text-align: center;
            color: #EC2829;
            box-shadow: 0px 1px 1px 1px rgba(0,0,0,.3);
            background: #ffffff;
        }

        .item {

            &:nth-child(2n+0) {
                background-color: #eeeeee;
            }

            h5 {
                color: #5F646E;
                font-size: .3rem;
                display: flex;
                align-items: center;
                justify-content: space-between;

                i {
                    font-style: normal;
                    margin-left: 0;
                }

                span {
                    color: #A5A6A8;
                    font-size: .26rem;
                }
            }

            ul {

                &.kuai3 {
                    display: flex;
                    justify-content: space-between;
                    width: 45%;
                    margin-bottom: 0.2rem;

                    li {
                        background-color: transparent;
                        box-shadow: none;

                        .dice {
                            font-size: .72rem;
                        }
                    }
                }

                &.fiveselect {

                    li {
                        font-size: .36rem;
                        width: .56rem;
                        height: .56rem;
                        line-height: .56rem;
                        border-radius: 100%;
                        color: #000000;
                        background-color: #ffffff;
                        box-shadow: inset 0px 0px 4px 2px var(--data-bg-color);
                    }
                }

                &.fc3d {

                    li {
                        font-size: .36rem;
                        width: .56rem;
                        height: .56rem;
                        line-height: .56rem;
                        border-radius: 100%;
                        background-color: #ffffff;
                        box-shadow: inset 0px 0px 4px 2px #EC2829;
                    }
                }

                &.klsf {

                    li {
                        font-size: .34rem;
                        width: .56rem;
                        height: .56rem;
                        line-height: .56rem;
                        border-radius: 100%;
                        color: #000000;
                        background-color: #ffffff;
                        box-shadow: inset 0px 0px 4px 2px #3985E1;
                    }
                }

                &.ffc {

                    li {
                        font-size: .34rem;
                        width: .56rem;
                        height: .56rem;
                        line-height: .56rem;
                        border-radius: 100%;
                        color: #000000;
                        background-color: #ffffff;
                        box-shadow: inset 0px 0px 4px 2px #3985E1;
                    }
                }

                &.ssc {

                    li {
                        font-size: .36rem;
                        width: .56rem;
                        height: .56rem;
                        line-height: .56rem;
                        border-radius: 100%;
                        background-color: #ffffff;
                        box-shadow: inset 0px 0px 4px 2px var(--data-bg-color);
                    }
                }

                &.lhc {

                    li {
                        color: #000000;
                        background-size: contain;
                        /*background-color: #ffffff;*/
                        box-shadow: none;
                        width: .58rem;
                        height: auto;
                        line-height: .58rem;
                        font-size: .28rem;
                        background-repeat: no-repeat;

                        &.red {
                            background-image: url('/static/assets/template-3/images/tradingfloor/icons/lhc-number-red.png');
                        }

                        &.blue {
                            background-image: url('/static/assets/template-3/images/tradingfloor/icons/lhc-number-blue.png');
                        }

                        &.green {
                            background-image: url('/static/assets/template-3/images/tradingfloor/icons/lhc-number-green.png');
                        }

                        span {
                            display: inline-block;
                            width: .5rem;
                        }

                        &:last-child {
                            margin-left: .25rem;

                            span {
                                position: relative;
                                right: .1rem;

                                &:before {
                                    display:none;
                                    content:'=';
                                    position: relative;
                                    right: .175rem;
                                }
                            }
                        }
                    }
                }

                &.pc28 {

                    &:after {
                        content:none;
                    }

                    li {
                        color: #000000;
                        width:.72rem;
                        height: .72rem;
                        line-height: .72rem;
                        border-radius: 100%;
                        margin-left:.175rem;
                        box-shadow: inset 0px 0px 4px 2px var(--data-color);
                        font-size: .36rem;
                        font-weight: normal;
                        margin-right: .1rem;

                        &:before {
                            margin-right: 0;
                            color: #5F646E;
                        }

                        &:last-child {
                            color: #000000!important;
                            background-color: transparent;
                            /*box-shadow: inset 0px 0px 4px 2px rgba(0,0,0,.4);*/
                        }
                    }
                }

                li {
                    border-radius: 3px;
                    font-size: .3rem;
                    width: .48rem;
                    height: .48rem;
                    line-height: .48rem;

                    &.wanneng {
                        border: 1px solid #B9D6FF;
                        border-top:0;
                        font-size: .3rem;
                        line-height: .75rem;
                        width: auto;
                        height: auto;

                        h4 {
                            background-color: #FFAE00;
                            color: #ffffff;
                            font-size: .3rem;
                            text-align: center;
                        }

                        .level-1 {
                            font-size: .34rem;
                            color: #FF0F0F;
                            text-align: center;
                            border-bottom: 1px solid #B9D6FF;
                        }

                        .level-2 {
                            font-size: .34rem;
                            display: flex;
                            justify-content: space-between;
                            text-align: center;
                            border-bottom: 1px solid #B9D6FF;

                            span {
                                width: 50%;

                                &:first-child {
                                    color: #FFAE00;
                                    border-right: 1px solid #B9D6FF;
                                }

                                &:last-child {
                                    color: $TEMPLATE2-theme-color;
                                }
                            }
                        }

                        .level-3 {
                            display: flex;
                            justify-content: space-between;
                            text-align: center;
                            border-bottom: 1px solid #B9D6FF;

                            span {
                                width: 50%;

                                &:first-child {
                                    border-right: 1px solid #B9D6FF;
                                }
                            }
                        }

                        .level-4 {
                            display: flex;
                            justify-content: space-between;

                            span {
                                width: 50%;
                                display: flex;                                
                                flex-wrap: wrap;
                                justify-content: space-between;
                                line-height: .5rem;
                                padding-top: .15rem;

                                &:first-child {
                                    border-right: 1px solid #B9D6FF;
                                }

                                b {
                                    width: 50%;
                                    text-align: center;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }
            }

            i {
                font-style: normal;
                margin-left: .5rem;

                span {
                    width:.72rem;
                    height: .72rem;
                    line-height: .72rem;
                    border-radius: 100%;
                    margin-right:.1rem;
                    box-shadow: inset 0px 0px 4px 2px rgba(75,183,255,1);
                    display: inline-block;
                    font-size: .28rem;
                    text-align: center;
                    vertical-align: top;
                }
            }
        }
    }

    &.template-2 {

        .item {
            display: flex;
            padding: .25rem 0;
            justify-content: space-around;

            &:nth-child(2n+0) {
                background-color: rgba(234, 248, 255, 1);
            }

            h5 {                
                margin: 0;

                span {
                    display: none;
                }

                i {
                    font-size: .24rem;

                    b {
                        color: #FF0F0F;
                    }
                }                
            }

            ul {
                width: auto;

                &.pc28 {

                    &:after {             
                        content:none;           
                        font-size: .36rem;
                    }

                    li {
                        font-size: .3rem;
                        width: .44rem;
                        height: .44rem;
                        line-height: .44rem;
                        margin: 0 .26rem 0 .13rem;
                        color: $TEMPLATE2-theme-color;
                        box-shadow: 0px 0px 1px 1.5px rgba(67, 147, 255, 1);
                    }
                }

                &.kuai3 {

                    li {
                        background-color: transparent;
                    }
                }

                &.lhc,
                &.xync, {

                    li {
                        background-color: var(--data-color)
                    }
                }

                li {
                    margin-right: 0;
                    font-size: .24rem;
                    width: .4rem;
                    height: .4rem;
                    line-height: .4rem;
                    color: #ffffff;
                    background-color: var(--data-bg-color)
                }
            }

            i {
                font-style: normal;
                font-size: .36rem;

                span {

                    &.red {
                        color: #FF0F0F;
                    }

                    &.blue {
                        color: $TEMPLATE2-theme-color;
                    }
                }
            }
        }
    }

    .item {
        padding: .25rem;

        &:nth-child(2n+0) {
            background-color: #f3f3f4;
        }

        h5 {
            font-size: .28rem;
            color: #999999;
            font-weight: 500;
            margin-bottom: .125rem;

            b {
                color: #333333;
                font-weight: 500;
            }

            + ul {
                display: initial;

                li {

                    &:first-child {
                        margin-left:0;
                    }
                }
            }

            + ul.pc28 {

                li {

                    &:first-child {
                        margin-left:0;
                    }
                }
            }
        }

        ul {
            list-style: none;
            text-align: center;
            display: flex;
            justify-content: center;
            width: 100%;

            li {
                font-size: .42rem;
                font-weight: 300;
                background-color: #ffffff;
                border-radius: 100%;
                margin:0 .1rem;
                display: inline-block;
                width: .58rem;height: .58rem;line-height: .58rem;
                box-shadow: 0px 0px 3px 0px rgba(57, 133, 225, 0.5);
                position: relative;
                color: var(--data-color);
                background-color: var(--data-bg-color);
            }

            &.dtclassic {
                justify-content: space-between;

                li {
                    margin: 0.05rem;
                }
            }

            &.pc28 {
                display: initial;

                &:after {
                    content:attr(dxds);
                    font-size: .42rem;
                }

                li {
                    font-size: .42rem;
                    font-weight: 300;
                    background-color: #ffffff;
                    border-radius: 100%;
                    margin: 0 .26rem;
                    display: inline-block;
                    width: .58rem;height: .58rem;line-height: .58rem;
                    box-shadow: 0px 0px 3px 0px rgba(57, 133, 225, 0.5);
                    position: relative;
                    color: var(--data-color);
                    background-color: var(--data-bg-color);

                    &.number-type {
                        width: auto;
                        background-color: transparent;
                        box-shadow: none;
                    }

                    &:before {
                        content:"+";
                        position: absolute;
                        right: 100%;
                        margin-right: .13rem;
                        color: #333333;
                    }

                    &:first-child {

                        &:before {
                            content:'';
                        }
                    }

                    &:last-child {
                        color: #ffffff!important;
                        background-color: var(--data-color);

                        &:before {
                            content:'=';
                        }
                    }
                }
            }
        }
    }
}
</style>